.header {
  background-color: #101010;
  align-items: center;
  color: #fff;
  padding:5px 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
}

.header h1{
  font-size: 2rem;
}
