.oneride {
  padding: 10;
  display: flex !important;
  justify-content: space-between;
}

.image img {
  width: 296px;
  height: 148px;
  border-radius: 5px;
  margin-right: 25px;
}

.oneride button {
  background-color: black;
  color: #fff;
  border:0;
  border-radius: 12px;
  padding:5px 10px ;
  margin: 0 10px;
  font-size: 0.75rem;
  
}

.fBlock{
    display: flex;
    justify-content: flex-start;
}

.middle p {
    margin: 5px;
    font-size: 1.2rem;
    color: rgb(211, 211, 211);
}

.middle span {
    color: #fff;
}