
.listItem  {

    padding:20px;

}




.listItem ul li {

    list-style-type: none;
    display: inline;
    margin: 0 10px;
    color: #999;
    font-size: 20px;

}

